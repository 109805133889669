import axios from "axios";

const WCAuth = axios.create({
  baseURL: process.env. VUE_APP_WC,
  headers:{
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  }
})


export default WCAuth;
