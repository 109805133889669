import {authHeader} from './auth-header';
import WCAuth from "@/api/WCAuth";

const login = async (email, password) => {

  return await WCAuth.post(`auth&email=${email}&password=${password}`)

}

const validate = async () => {

  let jwt = localStorage.getItem('jwt');

  return await WCAuth.get(`auth/validate&JWT=${jwt}`, {
    headers: authHeader()

  })


}


export const AuthWCServices = {
  login,
  validate
};
